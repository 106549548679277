const navigation = [
  {
    _tag: "CSidebarNavItem",
    name: "Schools",
    to: "/schools",
    icon: "cil-school",
  },
  {
    _tag: "CSidebarNavItem",
    name: "District",
    to: "/districts",
    icon: "cil-location-pin",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Pricing plans",
    to: "/pricing-plans",
    icon: "cil-list",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Feedbacks",
    to: "/feedbacks",
    icon: "cil-newspaper",
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "School Communication",
    icon: "cilSchool",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Legal page",
        to: "/legal-pages",
        icon: "cil-balance-scale",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Library",
        to: "/library",
        icon: "cil-library-add",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Admin tips",
        to: "/admin-tips",
        icon: "cil-library-add",
      },
    ]
  },
  {
    _tag: "CSidebarNavItem",
    name: "Notifications",
    to: "/notification-alerts",
    icon: "cil-bell-exclamation",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Languages",
    to: "/languages",
    icon: "cil-newspaper",
  },
  {
    _tag: "CSidebarNavItem",
    name: "ClassLink",
    to: "/classlink",
    icon: "cil-book",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Clever",
    to: "/clever",
    icon: "cil-book",
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Legal page",
  //   to: "/legal-pages",
  //   icon: "cil-balance-scale",
  // },
]

export default navigation
